<template>
  <v-app>
    <!-- 1°first ROW -->
    <div class="row">
      <div class="col-md-12">
        <!-- 1°first Card -->
        <v-card>
          <v-card-title>
            Categorías
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on}">
                <v-btn v-on="on" class @click="reinitialize" text icon color="blue">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Actualizar Tabla</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" class @click="exportExcel" text icon color="green">
                  <v-icon>fas fa-file-excel</v-icon>
                </v-btn>
              </template>
              <span>Exportar Excel</span>
            </v-tooltip>
          </v-card-title>
          <div v-if="loadTransition" class="alert bluesky-line m-0 mb-0"></div>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="category"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              sort-by="id"
              :search="search"
              class="elevation-1 datatable"
            >
              <template v-slot:top>
              <v-card class="mt-1">
                <v-spacer></v-spacer>
                <v-toolbar flat color="white">
                  <div class="kt-input-icon kt-input-icon--left">
                    <input
                      v-model="search"
                      append-icon="search"
                      label="Búsqueda"
                      type="text"
                      class="form-control form-control-md col-md-8"
                      placeholder="Buscar..."
                    />
                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                      <span>
                        <i class="la la-search"></i>
                      </span>
                    </span>
                  </div>
                  <!-- <v-text-field
                    class="text-xs-center"
                    v-model="search"
                    append-icon="search"
                    label="Búsqueda"
                  ></v-text-field>-->

                  <v-spacer></v-spacer>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-dialog v-model="dialog" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark class="mb-2 btn-starkoms-primary" v-bind="attrs" v-on="on">
                        <i class="fa fa-plus"></i> Categoría
                      </v-btn>
                    </template>
                    <!-- 1°second Card -->
                    <v-card>
                      <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-form class="px-3" ref="form" lazy-validation>
                          <v-container>
                            <!-- 2°second ROW -->
                            <v-row>
                              <v-col cols="12" sm="6" md="2" v-if="editedItem.id">
                                <v-text-field
                                  v-model="editedItem.id"
                                  name="id"
                                  label="ID"
                                  readonly="readonly"
                                  prepend-icon="vpn_key"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  v-model="editedItem.name"
                                  label="Categoría"
                                  prepend-icon="category"
                                  :rules="[v => !!v || 'Ingresar un nombre es requerido!']"
                                ></v-text-field>+
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                <v-select
                                  v-model="editedItem.categoria_superior"
                                  :items="category"
                                  item-text="name"
                                  item-value="id"
                                  label="Categoría Superior"
                                ></v-select>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#FF6347" text @click="close">Cancelar</v-btn>
                        <v-btn color="#00FF00" :disabled="!valid" text @click="save">Guardar</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
                </v-card>
              </template>
              <template v-slot:item.actions="{ item }">
                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on}">
                    <router-link :to="`/inventario/categories/${item.id}`">
                      <v-icon v-on="on" small class="mr-2">mdi-eye</v-icon>
                    </router-link>
                  </template>
                  <span>Ver Categoría</span>
                </v-tooltip>-->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on}">
                    <v-icon
                      small
                      v-on="on"
                      color="#93a2dd"
                      class="mr-2"
                      @click="editItem(item)"
                    >mdi-pencil</v-icon>
                  </template>
                  <span>Editar Categoría {{item.id}}</span>
                </v-tooltip>
              </template>
              <template v-slot:no-data>
                <v-btn color="#87CEFA" :loading="loading" @click="reinitialize()">Cargando ..</v-btn>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-model="page" :length="pageCount" :total-visible="10"></v-pagination>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    valid: true,
    loading: false,
    valid: true,
    dialog: false,
    loadTransition: false,
    windowSize: {
      x: 0,
      y: 0,
    },
    url: "",
    boolean: false,
    dateMsg:
      new Date().toISOString().substr(0, 10) +
      " " +
      new Date().toISOString().substr(11, 5),
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Categoría", value: "name" },
      { text: "Categoría Superiror", value: "categoria_superior_nombre" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    category: [],
    editedIndex: -1,
    search: "",
    editedItem: {
      id: 0,
      name: "",
      categoria_superior: 0,
      categoria_superior_nombre: "",
    },
    defaultItem: {
      id: 0,
      name: "",
      categoria_superior: 0,
      categoria_superior_nombre: "",
    },
  }),
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Categoría", route: "/inventario/categories" },
    ]);

    this.onResize();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Categoría" : "Editar Categoría";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loading = true;
      this.fetchCategories();
    },

    editItem(item) {
      this.editedIndex = this.category.indexOf(item);
      this.editedItem = Object.assign({}, item);
      console.log(Object.assign({}, this.category));
      //  console.log(Category);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      var vm = this;

      //

      if (this.editedIndex > -1) {
        //Object.assign(vm.category[vm.editedIndex], vm.editedItem);

        let editCategory = Object.assign({}, this.editedItem);
        //console.log(vm.editedItem.name);

        if (!vm.editedItem.name.length || vm.editedItem.name == null) {
          vm.$bvToast.toast("El nombre de la categoría no debe ir vacío!", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[1].focus();
          return;
        }
        if (this.$refs.form.validate()) {
          vm.loadTransition = true;

          this.axios({
            url: "inventory/categories/" + vm.editedItem.id,
            method: "PUT",
            data: {
              name: vm.editedItem.name,
              parent_category: vm.editedItem.categoria_superior,
            },
          })
            .then((response) => {
              vm.fetchCategories();

              vm.$bvToast.toast(
                `La Categoría: ${editCategory.name}, ha sido actualizada el ${vm.dateMsg} exitosamente!`,
                {
                  title: `Información`,
                  variant: "success",
                  solid: true,
                  toaster: "b-toaster-bottom-center",
                }
              );

              vm.loadTransition = false;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        // console.log(Object.assign(this.editItem));
        // this.category.push(this.editedItem);
        let createCategory = Object.assign({}, this.editedItem);

        if (!vm.editedItem.name.length || vm.editedItem.name == null) {
          vm.$bvToast.toast("El nombre de la categoría no debe ir vacío!", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[0].focus();
          return;
        }

        /*if (
          vm.editedItem.categoria_superior === 0 ||
          isNaN(vm.editedItem.categoria_superior)
        ) {
          vm.$bvToast.toast("Seleccione una categoría superior porfavor", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[1].focus();
          return;
        }*/
        if (this.$refs.form.validate()) {
          vm.loadTransition = true;

          this.axios({
            url: "inventory/categories",
            method: "POST",
            data: {
              name: vm.editedItem.name,
              parent_category: vm.editedItem.categoria_superior,
            },
          })
            .then((response) => {
              vm.fetchCategories();
              vm.$bvToast.toast(
                `La Categoría: ${createCategory.name}, ha sido creada correctamente!`,
                {
                  title: `Información`,
                  variant: "success",
                  solid: true,
                  toaster: "b-toaster-bottom-center",
                }
              );
              vm.loadTransition = false;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
      this.close();
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    fetchCategories() {
      var vm = this;

      this.axios({
        url: "inventory/categories",
        method: "GET",
      })
        .then((response) => {
          console.log(response.data.data);
          //Array of Child Category
          //console.log(response.data.data);
          vm.pageCount = Math.ceil(response.data.data.length / 10);
          let a = response.data.data;
          this.loading = false;
          a.map((row, idx) => {
            // console.log(typeof Number(row.parent_category));
            this.axios({
              //url: "inventory/categories/"+ 1,
              url: "inventory/categories/" + Number(row.parent_category),
              method: "GET",
            }).then((res) => {
              //object of Parent Category
              vm.pageCount = Math.ceil(response.data.data.length / 10);
              vm.category = response.data.data;
              //this.category.unshift(d);
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    reinitialize() {
      this.category = [];
      this.loading = true;
      this.fetchCategories();
    },
    exportExcel() {
      var vm = this;
      let headers = vm.headers.map((e) => e.text);
      let datos = vm.category;
      this.axios({
        url: "excel/from_array",
        method: "POST",
        data: {
          data: datos,
          cabeceras: headers,
        },
        responseType: "blob",
      })
        .then((response) => {
          const newBlob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          const data = window.URL.createObjectURL(newBlob);
          window.open(data, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>